<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Almacen - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="8"></b-col>
              <b-col sm="6" md="3">
                <b-input-group>
                  <b-form-input v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListWarehouse">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col sm="6" md="1">
                <b-link v-if="Permission('WarehouseAdd')"  class="btn form-control btn-primary" :to="{ path: '/almacen/nuevo' }" append><i class="fas fa-file"></i></b-link>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="4%" class="text-center">#</th>
                    <th width="10%" class="text-center">Código</th>
                    <th width="68%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-center">
                        <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                        <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                    </td> 
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('WarehouseEdit')"  @click="EditWarehouse(item.id_warehouse)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('WarehouseView')"  @click="ViewWarehouse(item.id_warehouse)">Ver</b-dropdown-item >
                        <b-dropdown-item v-if="Permission('WarehouseDelete')"  @click="ConfirmDeleteWarehouse(item.id_warehouse)">Eliminar</b-dropdown-item>
                
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListWarehouse" :total-rows="rows" :per-page="perPage"  align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>
<style>
div.modal-backdrop {
    opacity: .5;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
export default {
  name: "EstablishmentList",
  components:{
  },
  data() {
    return {
      module: 'Warehouse',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
      
    };
  },
  mounted() {
    this.ListWarehouse();
  },
  methods: {
    ListWarehouse,
    EditWarehouse,
    ViewWarehouse,
    ConfirmDeleteWarehouse,
    DeleteWarehouse,
    Permission,

    ShowModalSerie,
  },
  
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//listar usuario
function ListWarehouse() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "warehouse/list/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1 },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditWarehouse(id_warehouse) {
  this.$router.push({
    name: "WarehouseEdit",
    params: { id_warehouse: je.encrypt(parseInt(id_warehouse)) },
  });
}

// Ver Usuario
function ViewWarehouse(id_warehouse) {
  this.$router.push({
    name: "WarehouseView",
    params: { id_warehouse: je.encrypt(parseInt(id_warehouse)) },
  });
}

// Confirmar eliminar
function ConfirmDeleteWarehouse(id_warehouse) {
  Swal.fire({
    title: "Esta seguro de eliminar el almacen?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteWarehouse(id_warehouse);
    }
  });
}

// eliminar usuario
function DeleteWarehouse(id_warehouse) {
  let me = this;
  let url = this.url_base + "warehouse/delete/" + id_warehouse;
  axios({
    method: "delete",
    url: url,
    headers: { token: this.token, module: this.module, role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_warehouse).indexOf(response.data.result.id_warehouse);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}



function ShowModalSerie(id_warehouse) {
  EventBus.$emit('ModalSerieShow',id_warehouse);
}
</script>
